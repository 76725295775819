/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const FaqAccordion = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mb-14">
      <Accordion
        className="mb-5 overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600"
        open={open === 1}
        icon={<Icon id={1} open={open} />}
      >
        <AccordionHeader
          className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-700 dark:bg-jacarta-700 dark:text-white text-md"
          onClick={() => handleOpen(1)}
        >
          어떻게 거래하나요?
        </AccordionHeader>
        <AccordionBody className="accordion-body border-t border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700 text-base h-full">
          <div>
          1. 입금할 금액과 비트코인을 받을 주소를 입력하세요. 
          </div>
          <div className="mt-2">
          2. 예상 비트코인 개수를 확인하고 구매페이지 버튼을 누르세요.
          </div>
          <div className="mt-2">
          3. 입금문자를 확인하고 토스로 입금하세요. 
          </div>
          <div className="mt-2">
          4. 입금 후 구매페이지 화면에서 confirm을 기다립니다.
          </div>
          <div className="mt-2">
          (1분 내 비트코인이 입금됩니다.)
          </div>

        </AccordionBody>
      </Accordion>


      <Accordion
        className="mb-5 overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600"
        open={open === 3}
        icon={<Icon id={3} open={open} />}
      >
        <AccordionHeader
          className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-700 dark:bg-jacarta-700 dark:text-white text-md"
          onClick={() => handleOpen(3)}
        >
          P2P 거래의 장점은?
        </AccordionHeader>
        <AccordionBody className="accordion-body border-t border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700 text-base h-full">
          <div>
          1. 개인지갑으로 비트코인을 쉽게 옮길 수 있습니다. 수수료를 절약할 수 있습니다.
          </div>
          <div className="mt-2">
          2. 거래소 가입 없이 비트코인을 구매할 수 있습니다.
          </div>

        </AccordionBody>
      </Accordion>

      <Accordion
        className="mb-5 overflow-hidden rounded-lg border border-jacarta-100 dark:border-jacarta-600"
        open={open === 2}
        icon={<Icon id={2} open={open} />}
      >
        <AccordionHeader
          className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-700 dark:bg-jacarta-700 dark:text-white text-md"
          onClick={() => handleOpen(2)}
        >
          준비물이 무엇인가요?
        </AccordionHeader>
        <AccordionBody className="accordion-body border-t border-jacarta-100 bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-700 h-full text-base">
        <div >
        비트코인을 입금받을 월렛 오브 사토시 지갑 <a className="lowercase text-accent" href="https://www.walletofsatoshi.com">(https://www.walletofsatoshi.com/)</a>
        </div>
        <div className="mt-2">
          계좌이체를 위한 토스(toss) 앱
        </div>
        </AccordionBody>
      </Accordion>


    </div>
  );
};

export default FaqAccordion;
