import { create } from 'zustand'
import { persist } from 'zustand/middleware'




export interface UserInfo {
  name: string
  twitter_id:string
  katalk_id:string
}
// 판매자정보
export interface BTCSeller {
  uuid: string
  name: string
  discount: number
  max_amount_krw:number
  twitter_id:string
  katalk_id:string
  session_id:string
  session_register_cnt:number
}

export interface BTCBuyer {
  uuid: string
  name: string
  discount: number
  max_amount_btc:number
  twitter_id:string
  katalk_id:string
  session_id:string
  session_register_cnt:number

}


export interface IBtc {
  krw: number
  krwTime: string
  usd: number
  usdTime: string
}

interface IUpdateKRW {
  krw: number
  krwTime: string
}

interface IUpdateUSD {
  usd: number
  usdTime: string
}
export type MarketType = 'KRW' | 'USD' | 'KRW/USD'

interface BearState {
  btcSeller:BTCSeller[]
  btcBuyer:BTCBuyer[]
  userInfo:UserInfo[]

  market: MarketType
  setMarket: (market: MarketType) => void
  btc: IBtc // BTC 시세 정보
  amount: string // BTC 개수 Input 값
  isShow: boolean // 아코디언 토글
  isStandard:boolean
  // theme: 'dark' | 'light'
  setAmount: (by: string) => void
  updateKRW: (by: IUpdateKRW) => void
  updateUSD: (by: IUpdateUSD) => void
  toggleAcc: (flag: boolean) => void
  setStandardTest: (by:boolean) =>void
  setBTCSeller: (by:BTCSeller[]) =>void
  setBTCBuyer: (by:BTCBuyer[]) =>void
  setUserInfo: (by:UserInfo[]) =>void


}

export const useBearStore = create<BearState>()(
  persist(
    (set) => ({
      market: 'KRW/USD',
      setMarket: (market: MarketType) => set(() => ({ market })),
      btc: {
        krw: 0,
        krwTime: '',
        usd: 0,
        usdTime: '',
      },
      amount: '1',
      isShow: true,
      isSetting: false,
      isStandard: false,
      btcSeller:[],
      btcBuyer:[],
      userInfo:[],

      // theme: 'light',
      setAmount: (price) => set(() => ({ amount: price })),
      updateKRW: (krw) =>
        set((state) => ({
          btc: { ...state.btc, ...krw },
        })),
      updateUSD: (usd) =>
        set((state) => ({
          btc: { ...state.btc, ...usd },
        })),
      toggleAcc: (flag) => set(() => ({ isShow: flag })),
      setStandardTest: (flag) => set(() => ({ isStandard: flag })),
      setBTCSeller: (list) => set(() => ({ btcSeller: list })),
      setBTCBuyer: (list) => set(() => ({ btcBuyer: list })),
      setUserInfo: (list) => set(() => ({ userInfo: list })),
    }),
    { name: 'bear-storage' } // persist key
  )
)
